/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="lg:mt-[32px] mx-auto lg:max-w-[1400px] lg:px-4 lg:py-6 mt-0 px-0 py-0">
        <main>{children}</main>
        <footer>
          <div className="flex justify-center text-[#475467] text-center text-[16px] mt-[64px] mb-[10px] px-[32px] lg:px-0">
            <p>
              Big Brain Energy.AI © {new Date().getFullYear()}. Made with&nbsp;
              <a
                href="https://www.tiktok.com/@yamaliavkaa/video/7157416182616820997"
                target="_blank"
                className="pb-[0px] border-b border-[#141414] inline-flex"
              >
                🍺
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://www.tiktok.com/@gabinlondon/video/7059435150106496262?q=sunset%20primrose%20hill%20romantic&t=1716209988363"
                target="_blank"
                className="pb-[0px] border-b border-[#141414] inline-flex"
              >
                ❤️
              </a>
              &nbsp;in London 🇬🇧
            </p>
          </div>
          <div className="flex justify-center text-[#475467] text-center text-[16px] mb-[32px] px-[32px] lg:px-0">
            <p>
              See our&nbsp;
              <a
                href="/terms"
                target="_blank"
                className="pb-[0px] border-b border-[#141414] inline-flex text-black"
              >
                Terms and Conditions
              </a>
              &nbsp;and&nbsp;
              <a
                href="/privacy"
                target="_blank"
                className="text-black pb-[0px] border-b border-[#141414] inline-flex"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
